import React, {useState} from 'react';
import {AdminLayout} from './admin/layouts/AdminLayout'
import {PersonalLayout} from './personal/layouts/PersonalLayout'
import 'bootstrap/dist/css/bootstrap.min.css'
import {ModalState} from './personal/context/ModalContext'
import {UserState} from './personal/context/UserContext'


function App() {
    return(
        <>
            <UserState>
                <ModalState>
                    <PersonalLayout/>
                </ModalState>
            </UserState>
        </>
    )
}
export default App;
