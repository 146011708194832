import {IUser} from '../interfaces/IUser'

export function actionToken(token:string = ''){
    return {
        type: 'TOKEN',
        token: token
    }
}


export function actionUser(user:IUser|null = null){
    return {
        type: 'USER',
        user: user
    }
}