import React from 'react'

interface AddPaymentProps{
    paymentLink: string
}

export function AddPayment({paymentLink}:AddPaymentProps){
    return (
        <>
            <div className={'shadow p-3 mb-5 bg-body rounded'}>
                <h3 className={'mb-4'}>Пополнить баланс</h3>
                <div id="layout_pay" className="layout_pay">
                    <div className="form_for_payment" id="form_for_payment">
                        <iframe
                            frameBorder="0"
                            className={'w-100'}
                            scrolling="no"
                            src={paymentLink}
                                width="100" height="243"/>
                    </div>
                </div>
            </div>
        </>
    )
}