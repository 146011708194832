import axios from 'axios'

export function createUrl(uri: string):string{
    return `${process.env.REACT_APP_API_URL}/${uri}`
}

interface T {
}

export async function $post<T>(uri: string,token?: string, data:object = {},config:any = {
    headers: {
        "Content-type": "application/json;",
    }
}){
    if (token){
        config.headers.Authorization = `Bearer ${token}`
    }
    const url = createUrl(uri)
    return await axios.post(url,data,config)
}

export async function $get<T>(uri: string,token?: string, config:any = {
    headers: {
        "Content-type": "application/x-www-form-urlencoded;",
    }
}){
    if (token){
        config.headers.Authorization = `Bearer ${token}`
    }
    const url = createUrl(uri)
    return await axios.get(url,config)
}