import React, {useEffect, useState} from 'react'
import {Loader} from '../../components/Loader'
import {ErrorMessage} from '../../components/ErrorMessage'
import {InfoRow} from './InfoRow'
import {AddPayment} from './AddPayment'
import Form from 'react-bootstrap/Form'
import {useSelector} from 'react-redux'
import {IState} from '../../interfaces/IState'
import Pusher from 'pusher-js'
import {$get} from '../../api/http'
import {UserResponse} from '../hooks/usePersonalInfo'
import {actionUser} from '../../redux/actions'
import {useDispatch} from 'react-redux'

interface InfoProps{
   // user?:IUser
    loading:boolean
    error?:string
    paymentLink: string
}

// const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV || '', {
//     cluster: 'ap1'
// })
//
// Pusher.logToConsole = true

const pusher = new Pusher('6a898f46421ba62c4b53', {
    cluster: 'ap1'
});
interface IUpdateUserBalance{
    message:{
        user_id: number
    }
}


export function Info({loading,error,paymentLink}:InfoProps){

    const token = useSelector((state:IState) => state.token)
    const dispatch = useDispatch()
    const user = useSelector((state:IState)=> state.user)
    const [balanceLoading,setBalanceLoading] = useState<boolean>(false)

    const fetchUser = async (user_id:number)=>{
        if (user_id === user?.id){
            setBalanceLoading(true)
            try {
                const response = await $get<UserResponse>(`user`,token)
                const user = response.data.user
                dispatch(actionUser(user))
            }catch (e){
                console.warn(e)
            }
            setBalanceLoading(false)
        }
    }

    useEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const channel1 = pusher.subscribe('default-channel');
        channel1.bind('update-balance-event',function({message}:IUpdateUserBalance) {
            fetchUser(message.user_id)
        })

    },[])

    const success = !loading && !error && user
    const [modalPasswordActive,setModalPasswordActive] = useState<boolean>(false)
    const [modalProfileActive,setModalProfileActive] = useState<boolean>(false)
    const [modalBalanceActive,setModalBalanceActive] = useState<boolean>(false)

    const handlePasswordChange = () => {
        setModalPasswordActive(true)
    }
    const handleProfileChange = () => {
        setModalProfileActive(true)
    }
    const handleBalanceChange = () => {
        setModalBalanceActive(true)
    }

    const hide=()=>{
        setModalPasswordActive(false)
    }
    const show=()=>{
        setModalPasswordActive(true)
    }

    const hideProfile=()=>{
        setModalProfileActive(false)
    }
    const showProfile=()=>{
        setModalProfileActive(true)
    }
    const hideBalance=()=>{
        setModalBalanceActive(false)
    }
    const showBalance=()=>{
        setModalBalanceActive(true)
    }


    return (<>
     <div className="container">
         <div className="row">
             <div className="col">
                 <div className={'shadow p-3 mb-5 bg-body rounded'}>
                     <h3 className={'mb-4'}>Профиль</h3>
                     {loading && <Loader/>}
                     {error && <ErrorMessage error={error}/>}
                     {success && <table className="table table-striped table-bordered">
                         <thead>
                         </thead>
                         <tbody>
                         <tr>
                             <td>Ваш логин</td>
                             <td>{user.login}</td>
                         </tr>
                         <tr>
                             <td>Фамилия</td>
                             <InfoRow key={'1'} dataType="lastName" initialInputValue={user?.last_name || ''}/>
                         </tr>
                         <tr>
                             <td>Имя</td>
                             <InfoRow key={'2'} dataType="firstName" initialInputValue={user?.first_name || ''}/>
                         </tr>
                         <tr>
                             <td>Ваш сайт</td>
                             <td>
                                 <Form.Control
                                     type={'text'}
                                     size="sm"
                                     placeholder=""
                                     disabled={true}
                                     value={user?.site || ''}
                                 />
                             </td>
                         </tr>
                         <tr>
                             <td>Баланс</td>
                             <td>{balanceLoading ?
                                 <div className="spinner-border spinner-border-sm text-primary" role="status">
                                     <span className="sr-only">Loading...</span>
                                 </div>
                                 : user.balans}</td>
                         </tr>
                         </tbody>
                     </table>}
                 </div>
             </div>
             <div className="col">
                 <AddPayment paymentLink={paymentLink}/>
             </div>
         </div>
     </div>
    </>)
}