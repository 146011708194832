import React, {ChangeEvent, FormEvent, useState} from 'react'
import {IUser} from '../../interfaces/IUser'
import {Button, Form} from 'react-bootstrap'
import {ErrorMessage} from '../../components/ErrorMessage'
import { redirect } from "react-router-dom";
import {Loader} from './Loader'
import {$post} from '../../api/http'
import {AxiosError} from 'axios'
import {STATUS_VALIDATION_ERROR} from '../../api/statuses'
import {useSelector} from 'react-redux'
import {IState} from '../../interfaces/IState'

interface RegisterSiteProps{
    user:IUser
}

interface IRegisterSiteResponse{
    status: string
    message?: string
}

export function RegisterSite({user}:RegisterSiteProps){

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [result, setResult] = useState<string>('')
    const [site,setSite] = useState<string>('')

    const submitFormHandler = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault()
    }

    const token = useSelector((state:IState) => state.token)


    const addSite = async () => {
        setLoading(true)
        setError('')
        setResult('')
        try {
            const response = await $post<IRegisterSiteResponse>('register-site',token, {
                site:site
            })
            const status = response.data.status
            const message = response.data.message
            if (status === 'success'){
                return window.location.href='/'
            }else if(status==='error'){
                setError(message)
            }
        } catch (e){
            const error = e as AxiosError
            if (typeof error.response !== 'undefined' && error.response.status === STATUS_VALIDATION_ERROR){
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                for(let key in error.response.data.errors){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setError(error.response.data.errors[key][0])
                }
            }else {
                setError(error.message)
            }
        }
        setLoading(false)
    }

    const changeSite = (event:ChangeEvent<HTMLInputElement>) => {
        setSite(event.target.value.trim())
    }

    return (<>
        <div className="row mt-4  ">
            <div className="col">
                <h3>Подтверждение прав на сайт</h3>
                <div className="alert alert-info mt-4">Для продолжения работы необходимо задать информацию о сайте, на
                    котором планируется размещение каталогов. Пожалуйста, разместите код ниже на главной странице Вашего
                    сайта между тегами header и введите его адрес. После чего нажмитке кнопку Добавить


                </div>
            </div>
        </div>
        <div className="row mt-4 ">
            <div className="col">
                <div className="alert alert-warning ">
                    <div className={'p-2'}>
                        <code>
                            &lt;meta name="katalogi.top" content="68b1202bbbbf3318" /&gt;
                        </code>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <Form  onSubmit={submitFormHandler} className={'mt-4 d-flex justify-items-center'}>
                <Form.Group className="" controlId="formBasicEmail">
                    <Form.Control
                        onFocus={()=>{setError('')}}
                        onChange={changeSite} type="text"
                        disabled={loading} placeholder="example.com" />
                </Form.Group>
                <div className={'ml-2'}>
                    <Button variant="outline-primary"  className={'w-100'} type="submit" onClick={addSite} disabled={loading || !site}>
                        Добавить
                    </Button>
                </div>
            </Form>
            <div className={'mt-4'}>
                {error && <ErrorMessage error={error}/>}
                {loading && <Loader type={'danger'}/>}
            </div>
        </div>
    </>)
}