import React from 'react';
import { Link } from 'react-router-dom';
class NotFoundPage extends React.Component{
    render(){
        return <div>
            <h4 className={'text-center'}>Страница не найдена</h4>
            <p style={{textAlign:"center"}}>
                <Link to="/">На главную </Link>
            </p>
            <img src='/404.jpg'  alt={'Страница не существует'}/>
        </div>;
    }
}
export default NotFoundPage;