import React, {useContext, useState} from 'react'
import {useCatalogs} from '../hooks/useCatalogs'
import {Loader} from '../../components/Loader'
import {ErrorMessage} from '../../components/ErrorMessage'
import {CatalogRow} from './CatalogRow'
import {useDispatch, useSelector} from 'react-redux'
import {IState} from '../../interfaces/IState'
import {ModalComponent} from './ModalComponent'
import {ModalContext} from '../context/ModalContext'
import {ICatalog} from '../../interfaces/ICatalog'
import {$get, $post} from '../../api/http'
import {SimpleModal} from './SimpleModal'
import {useCatalogCode} from '../hooks/useCatalogCode'
import {AxiosError} from 'axios'
import {UserResponse} from '../hooks/usePersonalInfo'
import {actionUser} from '../../redux/actions'

export interface openModalProps{
    title: string,
    body: string
}

export function CatalogList(){
    const token = useSelector((state:IState)=> state.token)
    const {catalogs,loading,error, fetchCatalogs} = useCatalogs(token)
    const [modalTitle,setModalTitle] = useState<string>('')
    const [modalBody,setModalBody] = useState<string>('')
    const {modal,showModal,closeModal} = useContext(ModalContext)
    const [selectedCatalog,setSelectedCatalog] = useState<ICatalog>()
    const [selectedRentCount, setSelectedRentCount] = useState<number>(1)
    const [catalogCode,setCatalogCode] = useState<string>('')
    const dispatch = useDispatch()

    const openModal = ({title, body}:openModalProps) => {
        setModalTitle(title)
        setModalBody(body)
        showModal()
    }

    function getCatalogById(catalog_id:number):ICatalog{
        const filtered =  catalogs.filter((catalog:ICatalog) => catalog.id === catalog_id)
        return filtered[0]
    }

    async function paidCatalog(){
        if (typeof selectedCatalog !== 'undefined'){
            const result = await $post('paid-catalog',token,{
                catalog_id:selectedCatalog.id,
                rent_count:selectedRentCount
            })
        }
    }

    const handleOpen =(catalog:ICatalog, rentCount: number)=>{
        setSelectedCatalog(catalog)
        setSelectedRentCount(rentCount)
        openModal({
            title:`Подтвердите оплату`,
            body: `Каталог ${catalog.katalog} будет продлен на ${rentCount} мес., стоимость составит ${rentCount*catalog.price} руб, продолжить?`
        })
    }

    const handleConfirm = async ()=>{
        try {
            await paidCatalog()
            await fetchCatalogs()
            const response = await $get<UserResponse>(`user`,token)
            const user = response.data.user
            dispatch(actionUser(user))
        }catch (e){
            console.warn(e)
        }
        closeModal()
    }

    function b64_to_utf8(str: string): string {
        return decodeURIComponent(escape(window.atob(str)));
    }

    const [catalogModal, setCatalogModal] = useState<boolean>(false)

    const showCatalogModal = () =>{
        setCatalogModal(true)
    }

    const hideCatalogModal = () => {
        setCatalogModal(false)
    }

    async function fetchCatalogCode(catalog_id:number){
        try {
            const response = await $post('catalog-code',token,{
                catalog_id:catalog_id
            })
            let code = response.data.code
            return b64_to_utf8(code)
        }catch (e){
            const error = e as AxiosError
            console.warn(error)
            return error.message
        }
    }

    const handleGetCatalogCode = async (catalog_id:number) => {
        const code = await fetchCatalogCode(catalog_id);
        setSelectedCatalog(getCatalogById(catalog_id))
        setCatalogCode(code)
        showCatalogModal()
    }

    return (<>
    <div className="container">
        <div className={'shadow p-3 mb-5 bg-body rounded'}>
            <h3 className={'mb-4'}>Список каталогов</h3>
            {loading && <Loader/>}
            {error && <ErrorMessage error={error}/>}
            {!loading && !error && <table className={'table table-striped table-bordered'}>
                <thead>
                <tr>
                    <th>Каталог</th>
                    <th>Шаблон строки поиска</th>
                    <th>Дней аренды</th>
                    <th>Код</th>
                    <th>Цена</th>
                    <th colSpan={2}>Продлить</th>
                </tr>
                </thead>
                <tbody>
                {catalogs.map<JSX.Element>(catalog=><CatalogRow
                    key={catalog.id}
                    catalog={catalog}
                    openModal={openModal}
                    handleOpen={handleOpen}
                    handleGetCatalogCode={handleGetCatalogCode}
                    />
                )}
                </tbody>
            </table>}
            <ModalComponent title={modalTitle} handleConfirm={handleConfirm}>
                <div>{modalBody}</div>
            </ModalComponent>
            <SimpleModal
                active={catalogModal}
                closeModal={hideCatalogModal}
                openModal={showCatalogModal}
                okButton = {{
                    variant:'outline-warning',
                    size:'sm',
                    title:'Скопировать',
                    onClick: () => {navigator.clipboard.writeText(catalogCode)}
                }}
                closeButton = {{
                    variant:'outline-info',
                    size:'sm',
                    title:'Закрыть',
                    onClick: () => hideCatalogModal()
                }}
                title={`Код для каталога "${selectedCatalog?.katalog}"`}
            >
                <textarea
                    name="description" defaultValue={catalogCode}
                    className={'w-100 h-[200px] font-light'}/>
            </SimpleModal>
        </div>
    </div>
    </>)
}