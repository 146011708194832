import {createContext, useState} from 'react'

interface IModalContext{
   modal: boolean
   showModal: ()=>void
   closeModal: ()=>void
   confirm: ()=>void
}

export const ModalContext = createContext<IModalContext>({
    modal: false,
    showModal: () => {},
    closeModal: () => {},
    confirm: () => {},
})

interface ModalStateProps{
    children:JSX.Element
}

export const ModalState = ({children}:ModalStateProps):JSX.Element=>{

    const [modal, setModal] = useState<boolean>(false)
    const showModal = () => setModal(true)
    const closeModal = () => setModal(false)

    const confirm = () => {
        closeModal()
    }

    return(
        <ModalContext.Provider value={{modal,showModal,closeModal,confirm}}>
            {children}
        </ModalContext.Provider>
    )
}