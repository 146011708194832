import {IState} from '../interfaces/IState'

const initialState = {
    token: '',
    user: null
}

interface actionInterface{
    type: string
}

export default function reducer(state:IState = initialState, action:any = {type:'__INIT__'}) {
    switch (action.type) {
        case 'TOKEN':
            return {...state, token:action.token}
        case 'USER':
            return {...state, user:action.user}
        default:
            return {...state};
    }
}