import React, {useState, useEffect, ChangeEvent} from 'react'
import Form from 'react-bootstrap/Form'
import {useSelector} from 'react-redux'
import {IState} from '../../interfaces/IState'
import {$post} from '../../api/http'
import {AxiosError} from 'axios'

interface InfoRowProps{
    initialInputValue: string
    dataType: "firstName"|"lastName"|"site"|"password"
    inputType?:string
}

interface UserProfileResponse{
    status: string
    value: string
}

export function InfoRow({initialInputValue, dataType, inputType="text"}: InfoRowProps){

    const [loading, setLoading] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>(initialInputValue)
    const token = useSelector((state:IState) => state.token)

    const handleInputChange = (event:ChangeEvent<HTMLInputElement>) =>{
        setInputValue(event.target.value)
    }

    const saveInputValue = async () => {
        setLoading(true)
        try {
            const saveUserProfileResponse = await $post<UserProfileResponse>('save-user-profile',token,{
                data_type:dataType,
                input_value:inputValue ? inputValue : ''
            })
            setInputValue(saveUserProfileResponse.data.value)
        } catch (e){
            const error = e as AxiosError
        }
        setLoading(false)
    }

   return (
        <>
            <td>
                <Form.Control
                    type={inputType}
                    size="sm"
                    placeholder=""
                    disabled={loading}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={saveInputValue}
                />
            </td>
        </>
    );
}