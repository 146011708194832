import React, {useEffect, useState} from 'react'
import {useParams, Link} from 'react-router-dom'
import {$post} from '../../api/http'
import {AxiosError} from 'axios'
import {STATUS_UNAUTHORIZED} from '../../api/statuses'

export function VerifyEmailPage(){
    const params = useParams();
    const verify_email_token = params.token
    const [error, setError] = useState<string>('')
    const [verifySuccess, setVerifySuccess] = useState<boolean>(false)

    useEffect( () => {
        verifyEmail()
    },[])

    async function verifyEmail(){
        setError('')
        setVerifySuccess(false)
        try {
            await $post('verify-email','', {
                token: verify_email_token,
            })
            setVerifySuccess(true)
        } catch (e){
            const error = e as AxiosError
            if (typeof error.response !== 'undefined' && error.response.status === STATUS_UNAUTHORIZED){
                setError('Неверная или устаревшая ссылка')
            }else {
                setError(error.message)
            }
        }
    }

    return (
        <>
            {!error && verifySuccess &&
                <div className="row">
                    <div className={'alert alert-success mt-4'}>
                        Почтовый адрес подтвержден! Пожалуйста, <Link to={'/'}>авторизуйтесь</Link>
                    </div>
                </div>
            }
            {error &&
                <div className="row">
                    <div className={'alert alert-danger mt-4'}>
                        {error}
                    </div>
                </div>
            }
            {!error && !verifySuccess &&
                <h3>Проверям токен,минутку...</h3>
            }
        </>
    )
}