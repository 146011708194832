import {createContext, useState} from 'react'
import {IUser} from '../../interfaces/IUser'

interface IUserContext{
    user: any
    addUser: (user:IUser)=>void
}

export const UserContext = createContext<IUserContext>({
    user: {},
    addUser: (user:IUser) => {}
})

interface UserStateProps{
    children:JSX.Element
}

export const UserState = ({children}:UserStateProps):JSX.Element=>{
    const [user, setUser] = useState<IUser>()
    const addUser = (user:IUser)=>{setUser(user)}

    return(
        <UserContext.Provider value={{user,addUser}}>
            {children}
        </UserContext.Provider>
    )
}