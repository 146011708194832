import {useEffect, useState} from 'react'
import {IUser} from '../../interfaces/IUser'
import {AxiosError} from 'axios'
import {$get} from '../../api/http'
import {useDispatch} from 'react-redux'
import {actionToken, actionUser} from '../../redux/actions'

export interface UserResponse{
    user?: IUser|null
    payment_link: string
    status: string
}


export function usePersonalInfo(token: string){
   const [user, setUser] = useState<IUser>({
       balans: 0,
       charset: '',
       check_access_by_domain: 0,
       copyright: '',
       email: '',
       first_name: '',
       id: 0,
       ip: '',
       kuzov_target_self: 0,
       last_name: '',
       login: '',
       new: '',
       password: '',
       register_date: '',
       sha1: '',
       site: ''
   })

   const dispatch = useDispatch()
   const [loading, setLoading] = useState<boolean>(false)
   const [error, setError] = useState<string>('')
   const [paymentLink, setPaymentLink] = useState<string>('')

   useEffect( () => {
        fetchUser()
   },[])

   async function fetchUser(){
       setLoading(true)
       setError('')
       try {
           const response = await $get<UserResponse>(`user`,token)
           const user = response.data.user
           const payment_link = response.data.payment_link
           setPaymentLink(payment_link)
           if (user){
               setUser(user)
               dispatch(actionUser(user))
           }
           setLoading(false)
       }catch (e){
           const error = e as AxiosError
           console.warn(error.message)
           setLoading(false)
           localStorage.setItem('token','')
           dispatch(actionToken())
       }
   }

   return {user, loading, error, paymentLink}
}