import React, {useContext, useEffect} from 'react'
import {Info} from '../components/Info'
import {CatalogList} from '../components/CatalogList'
import {useSelector} from 'react-redux'
import {IState} from '../../interfaces/IState'
import {usePersonalInfo} from '../hooks/usePersonalInfo'
import {RegisterSite} from '../components/RegisterSite'
import {UserContext} from '../context/UserContext'

export function MainPage(){

    useEffect(()=>{
        document.title = 'Личный кабинет katalogi.top';
    },[])

    const token = useSelector((state:IState) => state.token)
    const {user, loading, error, paymentLink} = usePersonalInfo(token)
    const site:string|null = user.site || null
    const login:string = user.login

    return(<>
     {login && !site && <RegisterSite user={user}/>}
     {site &&
         <>
             <Info loading={loading} error={error} paymentLink={paymentLink}/>
             <CatalogList/>
         </>}
    </>)
}