import React, {useContext} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {ModalContext} from '../context/ModalContext'

interface ModalComponentProps{
    children:JSX.Element,
    title:string,
    options?:{
        size:"lg"|"xl"|"sm",
        closeBtnText:string,
        confirmBtnText:string
    },
    handleConfirm:()=>void
}


export function ModalComponent({children,title,options= {
    size: 'lg',
    closeBtnText: 'Закрыть',
    confirmBtnText: 'OK'
}, handleConfirm}:ModalComponentProps){

    const {modal,showModal,closeModal} = useContext(ModalContext)
    const size = options.size

    return(
        <Modal
            size={size}
            show={modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleConfirm}
                variant={'danger'}
                >
                    {options.confirmBtnText}</Button>
                <Button onClick={() => {
                    closeModal()
                }}>{options.closeBtnText}</Button>
            </Modal.Footer>
        </Modal>
    )
}