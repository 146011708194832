import {useState, useEffect} from 'react'
import {ICatalog} from '../../interfaces/ICatalog'
import axios, {AxiosError} from 'axios'
import {$get} from '../../api/http'

interface CatalogResponse{
    catalogs:ICatalog[]
    status:string
}

interface useCatalogsResponse{
    catalogs:ICatalog[],
    loading:boolean,
    error: string,
    fetchCatalogs: () => void
}

export function useCatalogs(token: string):useCatalogsResponse{
    const [catalogs, setCatalogs] = useState<ICatalog[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    async function fetchCatalogs(){
        try {
            setLoading(true)
            setError('')
            const response = await $get<CatalogResponse>(`catalogs`, token)
            const catalogs = response.data.catalogs
            setCatalogs(catalogs)
            setLoading(false)
        } catch (e){
            const error = e as AxiosError
            setError(error.message)
            setLoading(false)
            setCatalogs([])
        }
    }

    useEffect(()=>{
        fetchCatalogs()
    },[])

    return {catalogs, loading, error, fetchCatalogs}
}