import React from 'react'

interface ILoaderProps{
    type?: "primary"|"success"|"danger"
}

export function Loader({type}:ILoaderProps){
    const className = type === undefined ? "text-primary":`text-${type}`
    return (
        <div className={['spinner-border',className].join(' ')} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}