import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import {useDispatch, useSelector} from 'react-redux'
import {useAuthorized} from '../hooks/useAuthorized'
import {actionToken, actionUser} from '../../redux/actions'
import {usePersonalInfo} from '../hooks/usePersonalInfo'
import {UserContext} from '../context/UserContext'
import {IState} from '../../interfaces/IState'
import {IUser} from '../../interfaces/IUser'

export function Nav(){

    const dispatch = useDispatch()
    const auth = useAuthorized()

    const logoutHandler = () => {
        localStorage.setItem('token','')
        dispatch(actionToken())
        dispatch(actionUser())
    }

    const user = useSelector((state:IState) => state.user)

    return (<>
    <nav className="navbar bg-primary mb-4 h-[50px]">
        <div className="container">
            <div className="text-white text-uppercase text-white">
                <Link className={'text-white text-decoration-none'} to={'/'}>Katalogi.top</Link>
            </div>
            <div className={'flex flex-row justify-around'}>
                <div className="left">

                </div>
                <div className="right">
                    {user && <span className={'px-4 text-white'}>{user.login}</span>}
                    {auth && <Button
                        size="sm"
                        className={''}
                        variant="outline-warning"
                        onClick={logoutHandler}
                    >Выйти</Button>}
                </div>
            </div>
        </div>
    </nav>
    </>)
}