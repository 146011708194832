import React, {ReactChildren, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

interface IButtonInterface{
    title:string
    onClick:()=>void
    [key:string]: any
}

interface ModalComponentProps{
    active:boolean,
    openModal:()=>void
    closeModal:()=>void
    children: React.ReactNode
    title?:string
    okButton:IButtonInterface
    closeButton:IButtonInterface
}

export function SimpleModal({active,openModal,okButton,closeButton,closeModal,children,title = ''}:ModalComponentProps){
    const size = 'lg'

    return(
        <Modal
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            show={active}
            onHide={closeModal}
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button  {...okButton}>{okButton.title}</Button>
                <Button  {...closeButton}>{closeButton.title}</Button>
            </Modal.Footer>
        </Modal>
    )
}