import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {ErrorMessage} from '../../components/ErrorMessage'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {IUser} from '../../interfaces/IUser'
import {$post} from '../../api/http'
import {actionToken} from '../../redux/actions'
import {AxiosError} from 'axios/index'
import {STATUS_VALIDATION_ERROR} from '../../api/statuses'


interface UserData{
    email: string
    password:string
    repeatPassword:string
}

interface IRegisterResponse{
    status: string
    message: string
}

export function RegisterPage(){

    useEffect(()=>{
        document.title = 'Регистрация katalogi.top';
    },[])

    const submitFormHandler = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault()
    }

    const [userData, setUserData] = useState<UserData>({
        email: '', password: '', repeatPassword: ''
    })

    const changeEmail = (event:ChangeEvent<HTMLInputElement>) => {
        setUserData((prev:UserData) => {
            return {...prev, email:event.target.value}
        })
    }

    const changePassword = (event:ChangeEvent<HTMLInputElement>) => {
        setUserData((prev:UserData) => {
            return {...prev, password:event.target.value}
        })
    }

    const changeRepeatPassword = (event:ChangeEvent<HTMLInputElement>) => {
        setUserData((prev:UserData) => {
            return {...prev, repeatPassword:event.target.value}
        })
    }

    const [loading,setLoading] = useState<boolean>(false)
    const [error,setError] = useState<string>('')
    const [message,setMessage] = useState<string>('')

    async function registerUser(){
        setLoading(true)
        setError('')
        setMessage('')
        try {
            const response = await $post<IRegisterResponse>('register','', {
                email:userData.email,
                password:userData.password,
                password_confirmation: userData.repeatPassword
            })
            setMessage(response.data.message)
        } catch (e){
            const error = e as AxiosError
            if (typeof error.response !== 'undefined' && error.response.status === STATUS_VALIDATION_ERROR){
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                for(let key in error.response.data.errors){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setError(error.response.data.errors[key][0])
                }
            }else {
                setError(error.message)
            }
        }

        setLoading(false)
    }

    return (<>

            {message &&
                <div className="row">
                    <div className={'alert alert-success mt-4'}>
                        {message}
                    </div>
                </div>
            }

            {!message && <div className={'row'}>
            <div className="col"></div>
            <div className="col">
                <h3>Регистрация</h3>
                <Form  onSubmit={submitFormHandler} className={'mt-4'}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Эл. почта</Form.Label>
                        <Form.Control onChange={changeEmail} type="text" disabled={loading} placeholder="Введите email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="password" onChange={changePassword} disabled={loading} placeholder="Пароль" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPasswordRepeat">
                        <Form.Label>Повторите пароль</Form.Label>
                        <Form.Control type="password" onChange={changeRepeatPassword} disabled={loading} placeholder="Пароль" />
                    </Form.Group>
                    <div className={'d-flex justify-center align-items-center mb-3'}>
                        <Button variant="outline-primary"  className={'w-100 mt-4'} type="submit" onClick={()=>registerUser()} disabled={loading}>
                            Зарегистрироваться
                        </Button>
                    </div>

                    <div className={'mt-4'}>
                        {error && <ErrorMessage error={error}/>}
                    </div>
                    <div
                        className={'flex flex-row justify-between mt-4'}
                    >
                        <Link to={'/'}>Уже есть аккаунт?</Link>
                    </div>

                </Form>
            </div>
            <div className="col"></div>
            </div>
            }
    </>)
}