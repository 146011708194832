import React, {ChangeEvent, useState} from 'react'
import {ICatalog} from '../../interfaces/ICatalog'
import {Link} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {useSelector} from 'react-redux'
import {IState} from '../../interfaces/IState'
import {$post} from '../../api/http'
import {AxiosError} from 'axios'
import {openModalProps} from './CatalogList'

interface CatalogRowProps{
    catalog:ICatalog,
    openModal:({title, body}:openModalProps) => void
    handleOpen: (catalog:ICatalog, rentCount:number)=>void,
    handleGetCatalogCode:(catalog_id:number) => void
}

export function CatalogRow({catalog,openModal,handleOpen,handleGetCatalogCode}:CatalogRowProps){

    function calculateRentDays(license: string): number{
        const now = Math.floor(Date.now() / 1000)
        const maxtsmp = parseInt(license)
        const diff = Math.ceil((maxtsmp-now)/86400)
        return diff < 0 ? 0 : diff
    }

    const [rentCount, setRentCount] = useState<number>(1)
    const [searchString, setSearchString] = useState<string>(catalog.search_string)
    const token = useSelector((state:IState) => state.token)
    const [loading, setLoading] = useState<boolean>(false)

    const link = `https://katalogi.top/${catalog.path}`

    function handleChange(event:ChangeEvent<HTMLSelectElement>){
        setRentCount(parseInt(event.target.value))
    }

    const saveSearchString = async () => {
        setLoading(true)
        try {
            await $post('save',token,{
                catalog_id:catalog.id,
                search_string:searchString ? searchString : ''
            })
        } catch (e){
            const error = e as AxiosError
        }
        setLoading(false)
    }


    function handleInputChange(event:ChangeEvent<HTMLInputElement>){
        setSearchString(event.target.value)
    }
    return (
        <tr>
            <td>
                <Link to={link} target={'_blank'} className={'text-decoration-none'}>
                    {catalog.katalog}
                </Link>
            </td>
            <td>
                <Form.Control
                    type="text"
                    placeholder=""
                    disabled={loading}
                    value={searchString}
                    onChange={handleInputChange}
                    onBlur={saveSearchString}
                />
            </td>
            <td>{calculateRentDays(catalog.license)}</td>
            <td>
                <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={()=>handleGetCatalogCode(catalog.id)}
                >Код</Button>
            </td>
            <td>{catalog.price * rentCount}</td>
            <td colSpan={2}>
                <div className={'d-flex justify-content-between'}>
                    <Form.Select name="" id="" size={'sm'} value={rentCount} onChange={handleChange}>
                        <option value="1">1 месяц</option>
                        <option value="2">2 месяца</option>
                        <option value="3">3 месяца</option>
                        <option value="4">4 месяца</option>
                        <option value="5">5 месяцев</option>
                        <option value="6">6 месяцев</option>
                    </Form.Select>
                    &nbsp;
                    <Button
                        disabled={!rentCount}
                        onClick={()=>{
                            handleOpen(catalog, rentCount)
                        }}
                        size={'sm'}
                    >Продлить
                    </Button>
                </div>

            </td>
        </tr>
    )
}