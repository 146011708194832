import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import {Button, Form} from 'react-bootstrap'
import {ErrorMessage} from '../../components/ErrorMessage'
import {Loader} from '../components/Loader'
import {$post} from '../../api/http'
import {AxiosError} from 'axios'
import {STATUS_VALIDATION_ERROR} from '../../api/statuses'
import {useParams} from 'react-router-dom'
import {PasswordResetForm} from '../components/PasswordResetForm'

interface ResetPasswordPageProps{
    recaptchaKey: string
}

interface IRequestResetPassword{
    status: string
    message: string
}

export function ResetPasswordPage({recaptchaKey}:ResetPasswordPageProps){

    const params = useParams()
    const token = params.token || null

    useEffect(()=>{
        document.title = 'Восстановление пароля katalogi.top';
    },[])

    const [loading,setLoading] = useState<boolean>(false)
    const [error,setError] = useState<string>('')
    const [message,setMessage] = useState<string>('')
    const [email,setEmail] = useState<string>('')

    const submitFormHandler = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault()
    }

    const changeEmail = (event:ChangeEvent<HTMLInputElement>) =>{
        setEmail(event.target.value)
    }

    async function resetPassword(){
        setLoading(true)
        setError('')
      //  setMessage('')
        try{
            const response = await $post<IRequestResetPassword>('reset-password-request','',{
                email:email
            })
            setMessage(response.data.message)
        }catch (e){
            const error = e as AxiosError
            if (typeof error.response !== 'undefined' && error.response.status === STATUS_VALIDATION_ERROR){
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                for(let key in error.response.data.errors){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setError(error.response.data.errors[key][0])
                }
            }else {
                setError(error.message)
            }
        }
        setLoading(false)
    }

    return (<>
        {token && <PasswordResetForm token={token}/>}
        {!token && <div>
            <div className="row mt-4  ">
                <div className="col">
                    <h3>Восстановление пароля</h3>
                    <div className="alert alert-info mt-4">Введите адрес электронной почты</div>
                </div>
            </div>
            <div className="row">
                <Form onSubmit={submitFormHandler} className={'mt-4 d-flex justify-items-center'}>
                    <Form.Group className="" controlId="formBasicEmail">
                        <Form.Control
                            onFocus={() => {
                                setError('')
                            }}
                            onChange={changeEmail} type="text"
                            value={email}
                            disabled={loading}
                            placeholder="your_email@example.com"/>
                    </Form.Group>
                    <div className={'ml-2'}>
                        <Button variant="outline-primary" className={'w-100'} type="submit" onClick={resetPassword}
                                disabled={!!message || loading || !email}>
                            Восстановить пароль
                        </Button>
                    </div>
                </Form>
                <div className={'mt-4'}>
                    {error && <ErrorMessage error={error}/>}
                    {loading && <Loader type={'primary'}/>}
                </div>
                {message &&
                <div className="col">
                    <div className={'alert alert-success'}>
                        {message}
                    </div>
                </div>
                }
            </div>
        </div>}
    </>)
}