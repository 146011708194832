import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {ErrorMessage} from '../../components/ErrorMessage'
import {Link} from 'react-router-dom'
import {AxiosError} from 'axios'
import {STATUS_VALIDATION_ERROR} from '../../api/statuses'
import {$post} from '../../api/http'
import {Loader} from '../../components/Loader'

interface IPasswordResetFormProps{
    token: string
}

interface IPasswordSate{
    password:string
    password_confirmation:string
}

interface IResetPasswordResponse{
    status: string
}

export function PasswordResetForm({token}:IPasswordResetFormProps){

    const [error,setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState<boolean>(false)
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false)
    const [globalLoading, setGlobalLoading] = useState<boolean>(true)
    const [password,setPassword] = useState<IPasswordSate>({
        password:'',
        password_confirmation:''
    })
    const submitFormHandler = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault()
    }

    const changePassword = (event:ChangeEvent<HTMLInputElement>)=> {
        setError('')
        setPassword((prev:IPasswordSate) => {
            return {...prev,password:event.target.value}
        })
    }

    async function checkToken(){
        setGlobalLoading(true)
        try{
            const response = await $post<IResetPasswordResponse>('check-token','',{
                token:token,
            })
            setIsTokenValid(response.data.status === 'success')
        }catch (e){
            const error = e as AxiosError
            if (typeof error.response !== 'undefined' && error.response.status === STATUS_VALIDATION_ERROR){
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                for(let key in error.response.data.errors){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setError(error.response.data.errors[key][0])
                }
            }else {
                setError(error.message)
            }
        }
        setGlobalLoading(false)
    }

    useEffect(()=>{
        checkToken()
    },[])

    async function changePasswordRequest(){
        if (password.password!==password.password_confirmation){
            return setError('Пароль не совпадают')
        }
        if (password.password.length < 5){
            return setError('Минимальная длина пароля 5 символов')
        }
        setLoading(true)
        try{
            const response = await $post<IResetPasswordResponse>('reset-password','',{
                token:token,
                password: password.password,
                password_confirmation: password.password_confirmation
            })
            setPasswordChangedSuccessfully(response.data.status === 'success')
        }catch (e){
            const error = e as AxiosError
            if (typeof error.response !== 'undefined' && error.response.status === STATUS_VALIDATION_ERROR){
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                for(let key in error.response.data.errors){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setError(error.response.data.errors[key][0])
                }
            }else {
                setError(error.message)
            }
        }
        setLoading(false)
    }

    const changePasswordConfirmation = (event:ChangeEvent<HTMLInputElement>) => {
        setError('')
        setPassword((prev:IPasswordSate) => {
            return {...prev,password_confirmation:event.target.value}
        })
    }

    return (
    <>
        {globalLoading && <div>
            <h3>Проверка токена...</h3>
            <Loader/>
        </div>}
        {!globalLoading && passwordChangedSuccessfully && <div className={'alert alert-success mt-4'}>
               Новый пароль успешно сохранен Пожалуйста, <Link to={'/'}>авторизуйтесь</Link>
        </div>}

        {!globalLoading && !isTokenValid && <div className={'alert alert-danger mt-4'}>Токен не действительный!</div>}
        {!globalLoading && isTokenValid && !passwordChangedSuccessfully && <div>
            <div className="row">
                <div className="col"></div>
                <div className="col">
                    <h3>Задайте новый пароль</h3>
                </div>
                <div className="col"></div>
            </div>
            <div className="row">
                <div className="col"></div>
                <div className="col">
                    <Form  onSubmit={submitFormHandler} className={'mt-4'}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control onChange={changePassword} type="password" disabled={loading} placeholder="Введите пароль" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Подтверждение пароля</Form.Label>
                            <Form.Control type="password" onChange={changePasswordConfirmation} disabled={loading} placeholder="Повторите пароль" />
                        </Form.Group>
                        <div className={'d-flex justify-center align-items-center mb-3'}>
                            <Button variant="outline-primary"  className={'w-100 mt-4'} type="submit" onClick={()=>changePasswordRequest()} disabled={loading}
                            >
                                Сохранить
                            </Button>
                        </div>
                        <div className={'mt-4'}>
                            {error && <ErrorMessage error={error}/>}
                        </div>
                    </Form>
                </div>
                <div className="col"></div>
            </div>
        </div>}

    </>
    )
}