import React, {ChangeEvent, FormEvent, useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import {IUser} from '../../interfaces/IUser'
import {$post} from '../../api/http'
import {useDispatch} from 'react-redux'
import {actionToken, actionUser} from '../../redux/actions'
import {AxiosError} from 'axios'
import {ErrorMessage} from '../../components/ErrorMessage'
import {STATUS_UNAUTHORIZED} from '../../api/statuses'
import {Link} from 'react-router-dom'

interface UserData{
    email: string
    password:string
    rememberMe:number
}

export interface ILoginResponse{
    status:string
    message?:string
    user?:IUser
    authorisation?:{
        token:string
        type:string
    }
}

export function LoginPage(){

    useEffect(()=>{
        document.title = 'Авторизация katalogi.top';
    },[])

    const submitFormHandler = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault()
    }

    const [userData, setUserData] = useState<UserData>({
        email: '', password: '', rememberMe: 1
    })

    const dispatch = useDispatch()

    const changeEmail = (event:ChangeEvent<HTMLInputElement>) => {
        setUserData((prev:UserData) => {
            return {...prev, email:event.target.value}
        })
    }

    const changePassword = (event:ChangeEvent<HTMLInputElement>) => {
        setUserData((prev:UserData) => {
            return {...prev, password:event.target.value}
        })
    }

    const changeRememberMe = (event:ChangeEvent<HTMLInputElement>) => {
        setUserData((prev:UserData) => {
            return {...prev, rememberMe:event.target.checked ? 1:0}
        })
    }

    const [loading,setLoading] = useState<boolean>(false)
    const [error,setError] = useState<string>('')

    async function authorizeUser(){
        setLoading(true)
        setError('')
        try {
            const response = await $post<ILoginResponse>('login','', {
                email:userData.email,
                password:userData.password,
                rememberMe: userData.rememberMe
            })
            const token:string = response.data.authorization.token || null
            if(token){
                dispatch(actionToken(token))
                if (userData.rememberMe){
                    localStorage.setItem('token',token)
                }
            }
        } catch (e){
            const error = e as AxiosError
            if (typeof error.response !== 'undefined' && error.response.status === STATUS_UNAUTHORIZED){
                setError('Логин и/или пароль неверны')
            }else {
                setError(error.message)
            }
        }

        setLoading(false)
    }

    return (<>
        <div className="row">
            <div className="col"></div>
            <div className="col">
                <h3>Авторизация</h3>
                <Form  onSubmit={submitFormHandler} className={'mt-4'}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Эл. почта или логин</Form.Label>
                        <Form.Control onChange={changeEmail} type="text" disabled={loading} placeholder="Введите Ваш логин/email" />
                        <Form.Text className="text-muted">
                            Мы заботимся о конфиденциальности Ваших данных
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="password" onChange={changePassword} disabled={loading} placeholder="Пароль" />
                    </Form.Group>
                    <div className={'d-flex justify-between align-items-center mb-3'}>
                        <Form.Group  controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" checked={!!userData.rememberMe} onChange={changeRememberMe} label="Запомить меня" />
                        </Form.Group>
                        <Button variant="outline-primary" size={'sm'} type="submit" onClick={()=>authorizeUser()} disabled={loading}>
                            Войти
                        </Button>
                    </div>

                    <div className={'mt-3'}>
                        {error && <ErrorMessage error={error}/>}
                    </div>
                    <div
                     className={'flex flex-row justify-between'}
                    >
                        <Link to={'/register'}>Регистрация</Link>
                        <Link to={'/reset-password'}>Восстановление пароля</Link>
                    </div>

                </Form>
            </div>
            <div className="col"></div>
        </div>
    </>)
}