import React, {useEffect} from 'react'
import {Nav} from './../components/Nav'
import {Route, Routes} from 'react-router-dom'
import {MainPage} from '../pages/MainPage'
import {LoginPage} from '../pages/LoginPage'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {useAuthorized} from '../hooks/useAuthorized'
import {IState} from '../../interfaces/IState'
import {actionToken} from '../../redux/actions'
import {Footer} from '../components/Footer'
import {Users} from '../../admin/pages/Users'
import {RegisterPage} from '../pages/RegisterPage'
import {ResetPasswordPage} from '../pages/ResetPasswordPage'
import {VerifyEmailPage} from '../pages/VerifyEmailPage'
import NotFoundPage from '../pages/NotFoundPage'


export function PersonalLayout(){

    const token = useSelector((state:IState) => state.token)
    const dispatch = useDispatch()
    const authorized = useAuthorized()

    window.addEventListener('storage', (event:StorageEvent) => {
        if (event.key === 'token'){
            dispatch(actionToken(event.newValue || ''))
        }
    })
    useEffect(()=>{
        const storage_token:string = localStorage.getItem('token') || ''
        if (storage_token){
            dispatch(actionToken(storage_token))
        }
        // if (token){
        //     dispatch(actionToken(token))
        // }
    },[token])

    return (
        <>
            <Nav/>
            <div className="main-content">
                <div className="container mx-auto">
                    <Routes>
                        <Route path='/register' element={<RegisterPage/>}/>
                        <Route path='/reset-password' element={<ResetPasswordPage
                            recaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || 'my-dummy-key'}
                        />}/>
                        <Route path='/password-reset/:token' element={<ResetPasswordPage
                            recaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || 'my-dummy-key'}
                        />}/>
                        {!authorized && <Route path='/' element={<LoginPage/>}/>}
                        {authorized && <Route path='/' element={<MainPage/>}/>}
                        <Route path='/verify-email/:token' element={<VerifyEmailPage/>}/>
                        <Route path='*' element={<NotFoundPage/>}/>
                    </Routes>
                </div>
            </div>
            <Footer/>
        </>
    )
}